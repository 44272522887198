import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Editor, { type OnMount } from '@monaco-editor/react'
import YAML from 'yaml'
import { enqueueSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import {
  BackButtonGrey,
  ContentWrapper,
  MainHeader,
} from 'components/StyledComponents'
import useApi from 'hooks/useApi'
import { hasUnsavedChangesState } from 'state/formStates'
import { portalSettingState } from 'state/portalSettingStates'
import useRoute from 'hooks/useNavigate'
import { Path } from '../commonConstants'
import { type CategoriesData } from 'components/category/categoryTypes'
import {
  cleanCategoryEditorData,
  parseCategoryToEditorData,
} from 'utils/categoryUtils'

type OnMountParams = Parameters<OnMount>

const ErrorWrapper = styled(ContentWrapper)`
  width: 300px;
  color: ${({ theme }) => theme.palette.error.main};
  margin-left: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1)};
  white-space: pre-wrap;
`

const CategoryCodeEditorPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const editorRef = useRef<OnMountParams[0] | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const editorWrapperRef = useRef<HTMLDivElement>(null)
  const { sendPutRequest } = useApi()
  const portalSetting = useRecoilValue(portalSettingState)
  const { data: categoriesData } = useSWR<CategoriesData>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/categories/root`
      : null,
  )

  const [value, setValue] = useState('')
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (categoriesData) {
      try {
        const newValue = YAML.stringify(
          parseCategoryToEditorData(categoriesData.categories),
        )
        setValue(newValue)
      } catch (error) {
        console.log(error)
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        })
      }
    }
  }, [categoriesData, editorRef.current])

  const handleEditorDidMount: OnMount = (editor): void => {
    editorRef.current = editor
  }

  useEffect(() => {
    if (editorRef.current && editorWrapperRef.current) {
      editorRef.current.layout({
        width: editorWrapperRef.current.clientWidth,
        height: editorWrapperRef.current.clientHeight - 40,
      })
    }
  }, [error])

  const handleSave = async (): Promise<void> => {
    if (editorRef.current) {
      if (!portalSetting) {
        return
      }
      try {
        setIsSaving(true)
        const newValue = YAML.parse(editorRef.current.getValue())
        setError(null)

        await sendPutRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/categories/root`,
          { categories: cleanCategoryEditorData(newValue) },
        )

        enqueueSnackbar(
          formatMessage({ id: 'edit_categories.message.updated' }),
          {
            variant: 'success',
          },
        )

        goTo(Path.CATEGORIES_LIST, true)
      } catch (error) {
        setError(error as Error)
        editorRef.current.layout({ width: 0, height: 0 })
      } finally {
        setIsSaving(false)
        setHasUnsavedChanges(false)
      }
    }
  }

  const handleGoBack = (): void => {
    goTo(Path.CATEGORIES_LIST)
  }

  return (
    <Stack height="100%" width="100%">
      <Stack direction="row" spacing={1} marginBottom={2}>
        <BackButtonGrey
          onClick={handleGoBack}
          size="small"
          aria-label={formatMessage({
            id: 'general.icon_button.go_back',
          })}
        >
          <ArrowBackIcon />
        </BackButtonGrey>

        <MainHeader>
          {formatMessage({
            id: 'edit_categories.button.edit_categories',
          })}
        </MainHeader>
      </Stack>

      <Stack height="100%" flexGrow={1} ref={editorWrapperRef} spacing={1}>
        <Editor
          height="100%"
          defaultLanguage="yaml"
          value={value}
          onChange={(): void => {
            setHasUnsavedChanges(true)
          }}
          options={{
            tabSize: 2,
          }}
          onMount={handleEditorDidMount}
        />
        <LoadingButton
          variant="contained"
          size="small"
          loading={isSaving}
          onClick={() => {
            void handleSave()
          }}
          color="secondary"
        >
          {formatMessage({ id: 'general.button.save' })}
        </LoadingButton>
      </Stack>

      {error && <ErrorWrapper>{error.message}</ErrorWrapper>}
    </Stack>
  )
}

export default CategoryCodeEditorPage
