import { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { useForm, Controller } from 'react-hook-form'
import {
  headingsPlugin,
  listsPlugin,
  linkPlugin,
  linkDialogPlugin,
  quotePlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  BlockTypeSelect,
  ListsToggle,
  CreateLink,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { sortedSupportLanguagesSelector } from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import { type Locale } from 'types'
import { MDEditor } from 'components/StyledComponents'
import { themeModeState } from 'state/layoutStates'
import { ThemeMode } from 'commonConstants'

type TranslationDialogProps = {
  isOpen: boolean
  required?: boolean
  title: string
  defaultValue?: Record<Locale, string>
  maxLength?: number
  rows: number
  onSave: (data: Record<Locale, string>) => void
  onClose: () => void
  isRichEditor?: boolean
}

type TranslationFormData = {
  translations: Record<Locale, string>
}

const TranslationDialog: React.FC<TranslationDialogProps> = ({
  isOpen,
  required,
  title,
  defaultValue = {},
  maxLength,
  rows,
  onSave,
  onClose,
  isRichEditor,
}) => {
  const { formatMessage } = useIntl()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const themeMode = useRecoilValue(themeModeState)
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm<TranslationFormData>({
    mode: 'onTouched',
  })

  useEffect(() => {
    reset()
    const keys = Object.keys(defaultValue)
    keys.forEach((key) => {
      setValue(`translations.${key}`, defaultValue[key])
    })
  }, [JSON.stringify(defaultValue)])

  const handleSave = (): void => {
    const translations = getValues('translations')
    onSave(translations)
    reset()
  }

  const getErrorMessage = useCallback(
    (language: string) => {
      if (errors?.translations?.[language]?.type === 'maxLength') {
        return formatMessage(
          {
            id: 'general.error.max_length',
          },
          { max: maxLength },
        )
      }

      if (errors?.translations?.[language]?.type === 'required') {
        return formatMessage(
          {
            id: 'general.error.required',
          },
          { max: maxLength },
        )
      }

      return ''
    },
    [errors],
  )

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth={isRichEditor ? 'md' : 'sm'}
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        aria-label={formatMessage({
          id: 'general.button.close',
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack width="100%" spacing={1}>
          {supportLanguages.map((language) => (
            <FormControl
              key={language}
              error={!!errors?.translations?.[language]}
            >
              <Box>
                <Chip label={language} color="info" size="small"></Chip>
              </Box>

              <Controller
                name={`translations.${language}`}
                control={control}
                rules={{
                  maxLength,
                  required,
                }}
                defaultValue={''}
                render={({ field }) =>
                  isRichEditor ? (
                    <MDEditor
                      className={
                        themeMode === ThemeMode.DARK ? 'dark-theme' : ''
                      }
                      markdown={field.value}
                      onChange={(markdown) => {
                        field.onChange(markdown.replaceAll('&#x20;', ' '))
                      }}
                      plugins={[
                        headingsPlugin(),
                        listsPlugin(),
                        linkPlugin(),
                        quotePlugin(),
                        linkDialogPlugin(),
                        toolbarPlugin({
                          toolbarContents: () => (
                            <>
                              {' '}
                              <UndoRedo />
                              <BlockTypeSelect />
                              <BoldItalicUnderlineToggles />
                              <ListsToggle options={['bullet', 'number']} />
                              <CreateLink />
                            </>
                          ),
                        }),
                      ]}
                    />
                  ) : (
                    <MultiLineInput
                      {...field}
                      error={!!errors?.translations?.[language]}
                      maxLength={maxLength}
                      rows={rows}
                      variant="outlined"
                      fullWidth
                      helpMessage={getErrorMessage(language)}
                    />
                  )
                }
              />
            </FormControl>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={handleSave}
          variant="contained"
          disabled={!isValid || Object.keys(errors).length > 0}
        >
          {formatMessage({ id: 'general.button.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TranslationDialog
