import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

import SubCategorySelectorTree from './SubCategorySelectorTree'
import { TreeView } from 'components/category/CategoryStyledComponents'
import {
  type Category,
  type CategoryBasic,
} from 'components/category/categoryTypes'

type CategorySelectorTreeProps = {
  categories: Category[] | CategoryBasic[]
  selectedCategoryIds?: string
  isLoading?: boolean
  onCategoryChange: (categoryName: string) => void
}

const CategorySelectorTree: React.FC<CategorySelectorTreeProps> = ({
  selectedCategoryIds,
  categories,
  isLoading = false,
  onCategoryChange,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)

  useEffect((): void => {
    if (selectedCategoryIds) {
      setSelectedItemId(selectedCategoryIds)
    }
  }, [selectedCategoryIds])

  const handleCategoryChange = (ids: string): void => {
    setSelectedItemId(ids)
    onCategoryChange(ids)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <TreeView
      slots={{
        collapseIcon: Box,
        expandIcon: Box,
      }}
      defaultExpandedItems={selectedCategoryIds?.split('|') || []}
    >
      <SubCategorySelectorTree
        paths={[]}
        categories={categories}
        onCategoryChange={handleCategoryChange}
        selectedItemId={selectedItemId}
      />
    </TreeView>
  )
}

export default CategorySelectorTree
