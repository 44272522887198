import { useIntl } from 'react-intl'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/system/Box'
import CloseIcon from '@mui/icons-material/HighlightOff'

import { type Resource } from 'types'
import ResourceSlider from 'components/resource/ResourceSlider'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import { selectedResourceIndexState } from 'state/resourceStates'
import { getResourceFormat } from 'utils/fileUtils'
import { isMobileViewState, screenSizeState } from 'state/layoutStates'

type ResourceGalleryProps = {
  isOpen: boolean
  resources: Resource[]
  onClose: () => void
}

const Wrapper = styled(DialogContent)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) => theme.palette.background.default};
`

const ResourceListWrapper = styled(Stack)`
  width: 100%;
  align-self: center;
  flex-wrap: nowrap;
  overflow: auto;

  .MuiBox-root:first-of-type {
    margin-left: auto;
  }

  .MuiBox-root:last-of-type {
    margin-right: auto;
  }
`

const ResourceListMobileWrapper = styled(Stack)`
  width: 100%;
  align-self: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: ${({ theme }) => theme.spacing(1)};
  gap: ${({ theme }) => theme.spacing(1)};
  overflow-x: scroll;

  > * {
    scroll-snap-align: center;
  }

  .MuiBox-root:first-of-type {
    margin-left: auto;
  }

  .MuiBox-root:last-of-type {
    margin-right: auto;
  }
`

const ResourceGallery: React.FC<ResourceGalleryProps> = ({
  isOpen,
  resources,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const [selectedResourceIndex, setSelectedResourceIndex] = useRecoilState(
    selectedResourceIndexState,
  )
  const isMobileView = useRecoilValue(isMobileViewState)
  const screenSize = useRecoilValue(screenSizeState)

  const handleClose = (): void => {
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      fullScreen
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      <IconButton
        onClick={onClose}
        size="large"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        aria-label={formatMessage({
          id: 'general.button.close',
        })}
        color="inherit"
      >
        <CloseIcon fontSize="large" />
      </IconButton>
      <Wrapper>
        {isMobileView && (
          <Stack alignItems="center" width="100%">
            <ResourceListMobileWrapper
              overflow="auto"
              direction="row"
              spacing={1}
            >
              {resources?.map((resource, index) => (
                <Stack key={resource.uri} spacing={1}>
                  <ResourceWrapper
                    format={getResourceFormat(resource)}
                    url={resource.uri}
                    imageSize="contain"
                    width={screenSize.width * 0.9}
                    height={screenSize.height * 0.7}
                    selected={selectedResourceIndex === index}
                  />

                  <Box
                    padding={1}
                    width={screenSize.width * 0.9}
                    height={60}
                    textAlign={'center'}
                    overflow="auto"
                  >
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                      {resource?.description}
                    </Typography>
                  </Box>
                </Stack>
              ))}
            </ResourceListMobileWrapper>
          </Stack>
        )}

        {!isMobileView && (
          <Stack alignItems="center" width="100%">
            <ResourceSlider resources={resources} />
            <Box
              padding={1}
              width="80%"
              height={50}
              textAlign={'center'}
              overflow="auto"
            >
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {resources[selectedResourceIndex]?.description}
              </Typography>
            </Box>
            <ResourceListWrapper
              overflow="auto"
              direction="row"
              spacing={1}
              padding={1}
            >
              {resources?.map((resource, index) => (
                <ResourceWrapper
                  key={resource.uri}
                  format={getResourceFormat(resource)}
                  size={117}
                  url={resource.uri}
                  selected={selectedResourceIndex === index}
                  onClick={() => {
                    setSelectedResourceIndex(index)
                  }}
                  imageSize="cover"
                />
              ))}
            </ResourceListWrapper>
          </Stack>
        )}
      </Wrapper>
    </Dialog>
  )
}

export default ResourceGallery
