import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { mutate } from 'swr'
import dayjs from 'dayjs'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ToggleButton from '@mui/material/ToggleButton'
import MuiTextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import LoadingButton from '@mui/lab/LoadingButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PersonIcon from '@mui/icons-material/Person'
import PostAddIcon from '@mui/icons-material/PostAdd'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'

import useRoute from 'hooks/useNavigate'
import AddressInputWithMap from 'components/form/AddressInputWithMap'
import {
  type PublicNewCaseInfo,
  type PublicItemInfo,
  type PublicCaseCreationFormData,
  type PublicCaseInputData,
} from 'components/publicCase/publicCaseTypes'
import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import FileUploader from 'components/form/FileUploader'
import useApi from 'hooks/useApi'
import MultiLineInput from 'components/form/MultiLineInput'
import {
  EMAIL_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
  Path,
} from '../commonConstants'
import {
  CASE_DESCRIPTION_MAX_LENGTH,
  CASE_ATTACHMENTS_MAX_COUNT,
  Shareability,
} from 'components/case/caseConstants'
import {
  Accordion,
  BackButtonWhite,
  SelectBox,
  SubSubHeader,
} from 'components/StyledComponents'
import {
  hasUnsavedChangesState,
  isFileUploadingState,
  isPublicCaseCreatedState,
  isUnsavedConfirmDialogOpenState,
  processedFormFilesState,
} from 'state/formStates'
import { selectedLocationInfoState } from 'state/mapStates'
import PublicTopBanner from 'components/publicCase/PublicTopBanner'
import DateInput from 'components/form/DateInput'
import PublicFooter from 'components/publicCase/PublicFooter'
import { FILE_INPUT_ACCEPT_TYPE } from 'utils/fileConstants'
import LogoWrapper from 'components/LogoWrapper'
import usePortalSetting from 'hooks/usePortalSetting'
import { casesDataState } from 'state/caseListStates'
import {
  currentLocaleState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import InputAdornment from '@mui/material/InputAdornment'
import CaseShareabilityIcon from 'components/case/CaseShareabilityIcon'
import { AnonymousIcon } from 'components/icons/Icons'
import CategorySelectorTree from 'components/category/selector/CategorySelectorTree'
import { type CategoryBasic } from 'components/category/categoryTypes'
import { sortCategories } from 'utils/categoryUtils'

const ItemWrapper = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(2)};
  flex-wrap: wrap;
`

const ItemButton = styled(ToggleButton)(({ theme }) => ({
  width: '220px',
  height: '56px',
  '&:hover': {
    background: theme.palette.primary.contrastText,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  justifyContent: 'start',
}))

const TextField = styled(MuiTextField)`
  background: ${({ theme }) => theme.palette.background.paper};
`

const DisclaimerWrapper = styled(Box)`
  background: ${({ theme }) => theme.palette.error.main};
  padding: ${({ theme }) => theme.spacing(2)};
  color: white;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  font-size: 14px;
  white-space: pre-line;
`

enum AddCaseStep {
  ITEM = 'ITEM',
  ADDRESS = 'ADDRESS',
  CATEGORY = 'CATEGORY',
  CASE_INFO = 'CASE_INFO',
  VISIBILITY = 'VISIBILITY',
  REPORTER = 'REPORTER',
}

const FORM_NAME = 'consumerCase'

const PublicCaseAddPage: React.FC = () => {
  const { goTo } = useRoute()
  const { formatMessage, locale } = useIntl()
  const [searchParams] = useSearchParams()
  const portalId = searchParams.get('portalId')
  const [selectedCategoryName, setSelectedCategoryName] = useState<
    string | null
  >()
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)
  const [currentStep, setCurrentStep] = useState<AddCaseStep | null>(
    AddCaseStep.ITEM,
  )
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    getValues,
    watch,
    register,
    unregister,
    trigger,
  } = useForm<PublicCaseCreationFormData>({
    mode: 'all',
  })
  const categoryIds = watch('categoryIds')
  const anonymous = watch('anonymous')
  const shareable = watch('shareable')

  const { sendPostRequest } = useApi()
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const selectedLocationInfo = useRecoilValue(
    selectedLocationInfoState(FORM_NAME),
  )
  const {
    getLocalizedContent,
    formatPhoneNumber,
    retrieveCategoryAndParentsName,
  } = usePortalSetting()
  const [isSaving, setIsSaving] = useState(false)
  const [caseInputFields, setCaseInputFields] =
    useState<PublicCaseInputData | null>(null)
  const setPublicPortalInfo = useSetRecoilState(publicPortalInfoState)
  const casesData = useRecoilValue(casesDataState)
  const setCurrentLocale = useSetRecoilState(currentLocaleState)
  const setIsPublicCaseCreated = useSetRecoilState(isPublicCaseCreatedState)
  const [isUnsavedConfirmDialogOpen, setIsUnsavedConfirmDialogOpen] =
    useRecoilState(isUnsavedConfirmDialogOpenState)
  const [categories, setCategories] = useState<CategoryBasic[]>([])

  const getCaseInputFields = useCallback(async (): Promise<void> => {
    const response = await sendPostRequest(
      `${
        process.env.REACT_APP_PUBLIC_API_PATH ?? ''
      }/portals/${portalId}/cases:listInputs`,
    )
    const fieldsData = (await response.json()) as PublicCaseInputData
    setCaseInputFields(fieldsData)
    setCategories(sortCategories<CategoryBasic>(fieldsData.categories))

    setPublicPortalInfo(fieldsData.portal)

    const userLanguage = localStorage.getItem('userLanguage')
    const urlLang = searchParams.get('lang')

    setCurrentLocale(
      urlLang?.toUpperCase() ??
        userLanguage ??
        fieldsData.portal.defaultLanguage,
    )
    document.title = fieldsData.portal.name
  }, [portalId])

  useEffect(() => {
    setValue('occurred', dayjs(new Date()))
    void getCaseInputFields()

    const analytics = getAnalytics()
    logEvent(analytics, 'web_public_case_add_page_view')
  }, [])

  useEffect(() => {
    if (caseInputFields?.portalItems.length === 1) {
      setSelectedItemId(caseInputFields.portalItems[0].id)
      setCurrentStep(AddCaseStep.ADDRESS)
    }
  }, [caseInputFields])

  const selectedPortalItem = useMemo((): PublicItemInfo | null => {
    if (caseInputFields && selectedItemId) {
      const selectedItem = caseInputFields.portalItems.find(
        (item) => item.id === selectedItemId,
      )

      return selectedItem ?? null
    }

    return null
  }, [caseInputFields, selectedItemId])

  const handleSelectCaseItem = useCallback((itemId: string): void => {
    setSelectedItemId(itemId)

    setCurrentStep(AddCaseStep.ADDRESS)
  }, [])

  const handleStepChange =
    (newStep: AddCaseStep) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setCurrentStep(isExpanded ? newStep : null)
    }

  const onSubmit: SubmitHandler<PublicCaseCreationFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalId) {
        try {
          setIsSaving(true)
          const reporter = {
            fullName: data.reporterName ?? '',
            email: data.reporterEmail ?? '',
            phone: formatPhoneNumber(data.reporterPhone ?? ''),
          }
          const categoryIdsList = data.categoryIds.split('|')
          const lastCategoryId = categoryIdsList[categoryIdsList.length - 1]
          const formData: PublicNewCaseInfo = {
            categoryId: lastCategoryId,
            location: selectedLocationInfo,
            itemId: selectedItemId,
            description: data.description,
            locale,
            occurred: data.occurred?.format() || new Date().toISOString(),
            shareable: data.shareable,
            anonymous: data.anonymous,
          }

          if (!data.anonymous) {
            formData.reporter = reporter
          }

          if (processedFormFiles.length) {
            formData.resources = processedFormFiles.map((processedFile) => ({
              uri: processedFile.url,
              format: processedFile.format,
              name: processedFile.file.name,
            }))
          }

          const response = await sendPostRequest(
            `${
              process.env.REACT_APP_PUBLIC_API_PATH ?? ''
            }/portals/${portalId}/cases`,
            formData,
          )
          const newCaseData = await response.json()
          await mutate(
            `${
              process.env.REACT_APP_PUBLIC_API_PATH ?? ''
            }/portals/${portalId}/cases`,
            [...casesData, newCaseData],
          )

          goTo(Path.PUBLIC_CASE_LIST, true)
          setIsPublicCaseCreated(true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [
      selectedLocationInfo,
      locale,
      processedFormFiles,
      selectedItemId,
      portalId,
      casesData,
    ],
  )

  const handleClickNextStep = useCallback((newStep: AddCaseStep): void => {
    setCurrentStep(newStep)
  }, [])

  const hasUnsavedChanges = useMemo(
    () =>
      processedFormFiles.length > 0 ||
      !!selectedLocationInfo ||
      isDirty ||
      !!categoryIds,
    [processedFormFiles, selectedLocationInfo, isDirty, categoryIds],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  const handleGoBack = (): void => {
    goTo(Path.PUBLIC_CASE_LIST)
  }

  const handleCategoryChange = useCallback(
    (categoryIds: string): void => {
      if (caseInputFields) {
        setValue('categoryIds', categoryIds)
        const names = retrieveCategoryAndParentsName(
          categoryIds,
          caseInputFields.categories,
        ).join('> ')
        setSelectedCategoryName(names)
        setCurrentStep(AddCaseStep.CASE_INFO)
      }
    },
    [caseInputFields],
  )

  const descriptionErrorMessage = useMemo((): string | undefined => {
    if (errors.description?.type === 'maxLength') {
      return formatMessage(
        {
          id: 'case_add.step.case_information.error.max_length_description',
        },
        {
          max: CASE_DESCRIPTION_MAX_LENGTH,
        },
      )
    }

    if (errors.description?.type === 'required') {
      return formatMessage({
        id: 'case_add.step.case_information.error.required_description',
      })
    }

    return undefined
  }, [errors.description])

  useEffect(() => {
    if (anonymous) {
      unregister('reporterName', {
        keepValue: true,
      })
      unregister('reporterEmail', {
        keepValue: true,
      })
      unregister('reporterPhone', {
        keepValue: true,
      })
    } else {
      register('reporterName', {
        required: true,
        maxLength: USER_NAME_MAX_LENGTH,
      })
      register('reporterEmail', {
        maxLength: EMAIL_MAX_LENGTH,
        required: true,
        validate: (val): boolean => {
          if (!val) {
            return true
          }

          return isValidEmail(val)
        },
      })
      register('reporterPhone', {
        maxLength: PHONE_MAX_LENGTH,
        validate: (val): boolean => {
          if (!val) {
            return true
          }

          return isValidPhoneNumber(val)
        },
      })
      void trigger(['reporterName', 'reporterEmail', 'reporterPhone'])
    }
  }, [anonymous])

  const shouldDisableSaving = useMemo(
    () =>
      !isValid ||
      Object.keys(errors).length > 0 ||
      isFileUploading ||
      !selectedLocationInfo ||
      (!categoryIds && !!caseInputFields?.categories?.length),
    [
      isValid,
      errors,
      isSaving,
      isFileUploading,
      categoryIds,
      caseInputFields,
      anonymous,
    ],
  )

  const disclaimers = useMemo(
    () => getLocalizedContent(selectedPortalItem?.disclaimers),
    [selectedPortalItem],
  )

  const handleDiscard = (): void => {
    setIsUnsavedConfirmDialogOpen(false)
    setHasUnsavedChanges(false)
  }

  const handleContinue = (): void => {
    setIsUnsavedConfirmDialogOpen(false)
  }

  return (
    /* eslint-disable @typescript-eslint/no-misused-promises */
    <Box height={'100vh'} width="100%">
      <Stack height={'100%'} width="100%" overflow="hidden">
        <Box>
          <PublicTopBanner />
        </Box>
        <Box flexGrow={1} overflow="auto" padding={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item sm={0} md={2} lg={3}></Grid>
              <Grid item sm={12} md={8} lg={6}>
                <Stack spacing={2} width={'100%'} padding={2}>
                  <Stack direction={'row'} spacing={2}>
                    <BackButtonWhite
                      onClick={handleGoBack}
                      size="small"
                      aria-label={formatMessage({
                        id: 'general.icon_button.go_back',
                      })}
                    >
                      <ArrowBackIcon />
                    </BackButtonWhite>
                    <Typography variant="h5" flexGrow={1}>
                      {formatMessage({ id: 'consumer_report_add.header' })}
                    </Typography>
                  </Stack>

                  {disclaimers && (
                    <DisclaimerWrapper>{disclaimers}</DisclaimerWrapper>
                  )}

                  {caseInputFields &&
                    caseInputFields.portalItems.length > 1 && (
                      <Accordion
                        defaultExpanded={true}
                        expanded={currentStep === AddCaseStep.ITEM}
                        onChange={handleStepChange(AddCaseStep.ITEM)}
                        disableGutters
                        elevation={0}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems="center"
                          >
                            <CheckCircleIcon
                              color={selectedItemId ? 'success' : 'disabled'}
                            />

                            <Typography variant="h6">
                              {selectedPortalItem &&
                              currentStep !== AddCaseStep.ITEM
                                ? getLocalizedContent(selectedPortalItem.names)
                                : formatMessage({
                                    id: 'consumer_report_add.label.choose_related_item',
                                  })}
                            </Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails sx={{ paddingX: 6 }}>
                          <ItemWrapper direction={'row'}>
                            {caseInputFields?.portalItems.map((item) => (
                              <ItemButton
                                key={item.id}
                                size="small"
                                value={item.id}
                                selected={selectedItemId === item.id}
                                onChange={() => {
                                  handleSelectCaseItem(item.id)
                                }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="center"
                                  width={'100%'}
                                >
                                  {item.logoUrl && (
                                    <LogoWrapper
                                      size={40}
                                      url={item.logoUrl}
                                      alt={getLocalizedContent(item.names)}
                                    />
                                  )}
                                  <Box flexGrow={1} textAlign={'left'}>
                                    {getLocalizedContent(item.names)}
                                  </Box>
                                </Stack>
                              </ItemButton>
                            ))}
                          </ItemWrapper>
                        </AccordionDetails>
                      </Accordion>
                    )}

                  <Accordion
                    defaultExpanded={false}
                    expanded={currentStep === AddCaseStep.ADDRESS}
                    onChange={handleStepChange(AddCaseStep.ADDRESS)}
                    disabled={!selectedItemId}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack direction={'row'} spacing={1} alignItems="center">
                        <CheckCircleIcon
                          color={
                            selectedLocationInfo?.address
                              ? 'success'
                              : 'disabled'
                          }
                        />

                        <Typography variant="h6">
                          {selectedLocationInfo &&
                          currentStep !== AddCaseStep.ADDRESS
                            ? selectedLocationInfo.address
                            : formatMessage({
                                id: 'consumer_report_add.label.specify_location',
                              })}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingX: 6 }}>
                      <Stack spacing={2}>
                        {caseInputFields && (
                          <AddressInputWithMap
                            mapHeight={400}
                            selectedPosition={
                              selectedPortalItem?.location?.position ||
                              caseInputFields.portal.mapConfiguration?.centre
                            }
                            center={
                              caseInputFields.portal.mapConfiguration?.centre
                            }
                            region={
                              caseInputFields.portal.mapConfiguration?.region
                            }
                            language={caseInputFields.portal.defaultLanguage.toLowerCase()}
                            zoom={
                              caseInputFields.portal.mapConfiguration?.zoomLevel
                            }
                            formName={FORM_NAME}
                          />
                        )}

                        <Box textAlign={'right'}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              handleClickNextStep(AddCaseStep.CATEGORY)
                            }}
                          >
                            {formatMessage({ id: 'general.button.next' })}
                          </Button>
                        </Box>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>

                  {!!caseInputFields?.categories?.length && (
                    <Accordion
                      defaultExpanded={false}
                      expanded={currentStep === AddCaseStep.CATEGORY}
                      onChange={handleStepChange(AddCaseStep.CATEGORY)}
                      disabled={!selectedItemId}
                      disableGutters
                      elevation={0}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems="center"
                        >
                          <CheckCircleIcon
                            color={
                              selectedCategoryName ? 'success' : 'disabled'
                            }
                          />

                          <Typography variant="h6">
                            {selectedCategoryName &&
                            currentStep !== AddCaseStep.CATEGORY
                              ? selectedCategoryName
                              : formatMessage({
                                  id: 'consumer_report_add.label.select_category',
                                })}
                          </Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingX: 6 }}>
                        <CategorySelectorTree
                          selectedCategoryIds={categoryIds}
                          categories={categories}
                          onCategoryChange={handleCategoryChange}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}

                  <Accordion
                    defaultExpanded={false}
                    expanded={currentStep === AddCaseStep.CASE_INFO}
                    onChange={handleStepChange(AddCaseStep.CASE_INFO)}
                    disabled={!selectedItemId}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack direction={'row'} spacing={1} alignItems="center">
                        <CheckCircleIcon
                          color={
                            getValues('description') ? 'success' : 'disabled'
                          }
                        />

                        <Typography variant="h6">
                          {formatMessage({
                            id: 'consumer_report_add.label.report_information',
                          })}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingX: 6 }}>
                      <Stack spacing={2}>
                        <FormControl error={!!errors.description}>
                          <Controller
                            name="description"
                            control={control}
                            rules={{
                              required: true,
                              maxLength: CASE_DESCRIPTION_MAX_LENGTH,
                            }}
                            defaultValue=""
                            render={({ field }) => (
                              <MultiLineInput
                                {...field}
                                required
                                error={!!errors.description}
                                maxLength={CASE_DESCRIPTION_MAX_LENGTH}
                                label={formatMessage({
                                  id: 'case_add.step.case_information.label.description',
                                })}
                                variant="outlined"
                                fullWidth
                                rows={5}
                                helpMessage={descriptionErrorMessage}
                              />
                            )}
                          />
                        </FormControl>

                        <FormControl>
                          <Controller
                            name="occurred"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                              <DateInput
                                {...field}
                                label={formatMessage({
                                  id: 'consumer_report_add.label.occurred',
                                })}
                                allowOnlyPast={true}
                                showTime={true}
                              />
                            )}
                          />
                        </FormControl>

                        <Box>
                          <Typography variant="body2">
                            {formatMessage(
                              {
                                id: 'consumer_report_add.label.image_upload',
                              },
                              {
                                max: CASE_ATTACHMENTS_MAX_COUNT,
                              },
                            )}
                          </Typography>
                          <FileUploader
                            formName={FORM_NAME}
                            accept={[
                              FILE_INPUT_ACCEPT_TYPE.IMAGE,
                              FILE_INPUT_ACCEPT_TYPE.DOCUMENT,
                              FILE_INPUT_ACCEPT_TYPE.EMAIL,
                            ]}
                            limit={CASE_ATTACHMENTS_MAX_COUNT}
                            isPublic={true}
                          />
                        </Box>
                      </Stack>

                      <Box textAlign={'right'} marginTop={2}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            handleClickNextStep(
                              selectedPortalItem?.configuration.caseShareable
                                ? AddCaseStep.VISIBILITY
                                : AddCaseStep.REPORTER,
                            )
                          }}
                        >
                          {formatMessage({ id: 'general.button.next' })}
                        </Button>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  {selectedPortalItem?.configuration.caseShareable && (
                    <Accordion
                      defaultExpanded={false}
                      expanded={currentStep === AddCaseStep.VISIBILITY}
                      onChange={handleStepChange(AddCaseStep.VISIBILITY)}
                      disabled={!selectedItemId}
                      disableGutters
                      elevation={0}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems="center"
                        >
                          <CheckCircleIcon color={'success'} />
                          <Typography variant="h6">
                            {currentStep === AddCaseStep.VISIBILITY
                              ? formatMessage({
                                  id: 'consumer_report_add.label.visibility',
                                })
                              : shareable
                              ? formatMessage({
                                  id: 'case_detail.label.shareable',
                                })
                              : formatMessage({
                                  id: 'case_detail.label.private',
                                })}
                          </Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails sx={{ paddingX: 6 }}>
                        <Stack spacing={2}>
                          <Typography variant="body2">
                            {formatMessage({
                              id: 'consumer_report_add.description.visibility',
                            })}
                          </Typography>
                          <Stack direction="row">
                            <Controller
                              name="shareable"
                              control={control}
                              defaultValue={true}
                              render={({ field }) => (
                                <Stack direction={'row'}>
                                  <SelectBox
                                    spacing={1}
                                    direction={'row'}
                                    alignItems="center"
                                    selected={shareable ? 'selected' : ''}
                                    onClick={() => {
                                      setValue('shareable', true)
                                      setCurrentStep(AddCaseStep.REPORTER)
                                    }}
                                  >
                                    <CaseShareabilityIcon
                                      shareability={Shareability.SHARED}
                                    />
                                    <Stack flexGrow={1}>
                                      <SubSubHeader>
                                        {formatMessage({
                                          id: 'case_detail.label.shareable',
                                        })}
                                      </SubSubHeader>
                                      <Typography variant="caption">
                                        {formatMessage({
                                          id: 'consumer_report_add.description.shareable',
                                        })}
                                      </Typography>
                                    </Stack>
                                  </SelectBox>
                                  <SelectBox
                                    spacing={1}
                                    direction={'row'}
                                    alignItems="center"
                                    selected={shareable ? '' : 'selected'}
                                    onClick={() => {
                                      setValue('shareable', false)
                                      setCurrentStep(AddCaseStep.REPORTER)
                                    }}
                                  >
                                    <CaseShareabilityIcon
                                      shareability={Shareability.PRIVATE}
                                    />
                                    <Stack flexGrow={1}>
                                      <SubSubHeader>
                                        {formatMessage({
                                          id: 'case_detail.label.private',
                                        })}
                                      </SubSubHeader>
                                      <Typography variant="caption">
                                        {formatMessage({
                                          id: 'consumer_report_add.description.private',
                                        })}
                                      </Typography>
                                    </Stack>
                                  </SelectBox>
                                </Stack>
                              )}
                            />
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  <Accordion
                    defaultExpanded={false}
                    expanded={currentStep === AddCaseStep.REPORTER}
                    onChange={handleStepChange(AddCaseStep.REPORTER)}
                    disabled={!selectedItemId}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack direction={'row'} spacing={1} alignItems="center">
                        <CheckCircleIcon
                          color={
                            getValues('reporterName') ||
                            getValues('reporterEmail') ||
                            getValues('reporterPhone')
                              ? 'success'
                              : 'disabled'
                          }
                        />
                        <Typography variant="h6">
                          {getValues('reporterName') &&
                          currentStep !== AddCaseStep.REPORTER
                            ? getValues('reporterName')
                            : formatMessage({
                                id: 'consumer_report_add.label.contact_details',
                              })}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingX: 6 }}>
                      <Stack spacing={1}>
                        <Stack flexGrow={1} spacing={2}>
                          <Typography variant="body2">
                            {formatMessage({
                              id: 'consumer_report_add.text.contact_details',
                            })}
                          </Typography>

                          {selectedPortalItem?.configuration
                            .anonymousReporting && (
                            <Stack direction="row">
                              <Controller
                                name="anonymous"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <Stack direction={'row'}>
                                    <SelectBox
                                      spacing={1}
                                      direction={'row'}
                                      alignItems="center"
                                      selected={anonymous ? '' : 'selected'}
                                      onClick={() => {
                                        setValue('anonymous', false)
                                      }}
                                    >
                                      <PersonIcon />
                                      <Stack flexGrow={1}>
                                        <SubSubHeader>
                                          {formatMessage({
                                            id: 'consumer_report_add.label.with_reporter_info',
                                          })}
                                        </SubSubHeader>
                                        <Typography variant="caption">
                                          {formatMessage({
                                            id: 'consumer_report_add.description.with_reporter_info',
                                          })}
                                        </Typography>
                                      </Stack>
                                    </SelectBox>
                                    <SelectBox
                                      spacing={1}
                                      direction={'row'}
                                      alignItems="center"
                                      selected={anonymous ? 'selected' : ''}
                                      onClick={() => {
                                        setValue('anonymous', true)
                                      }}
                                    >
                                      <AnonymousIcon />
                                      <Stack flexGrow={1}>
                                        <SubSubHeader>
                                          {formatMessage({
                                            id: 'consumer_report_add.label.send_anonymously',
                                          })}
                                        </SubSubHeader>
                                        <Typography variant="caption">
                                          {formatMessage({
                                            id: 'consumer_report_add.description.send_anonymously',
                                          })}
                                        </Typography>
                                      </Stack>
                                    </SelectBox>
                                  </Stack>
                                )}
                              />
                            </Stack>
                          )}

                          {!anonymous && (
                            <>
                              <FormControl
                                error={!!errors.reporterName}
                                fullWidth
                              >
                                <Controller
                                  name="reporterName"
                                  control={control}
                                  defaultValue={''}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      required={true && !anonymous}
                                      error={!!errors.reporterName}
                                      size="small"
                                      label={formatMessage({
                                        id: 'consumer_report_add.label.reporter.name',
                                      })}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <PersonIcon
                                              color="primary"
                                              fontSize="small"
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />

                                {errors.reporterName?.type === 'maxLength' && (
                                  <FormHelperText>
                                    {formatMessage(
                                      {
                                        id: 'general.error.max_length',
                                      },
                                      { max: USER_NAME_MAX_LENGTH },
                                    )}
                                  </FormHelperText>
                                )}
                              </FormControl>

                              <FormControl
                                error={!!errors.reporterEmail}
                                fullWidth
                              >
                                <Controller
                                  name="reporterEmail"
                                  control={control}
                                  defaultValue={''}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      required={!anonymous}
                                      error={!!errors.reporterEmail}
                                      size="small"
                                      label={formatMessage({
                                        id: 'consumer_report_add.label.reporter.email',
                                      })}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <EmailIcon
                                              color="primary"
                                              fontSize="small"
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                                {errors.reporterEmail?.type === 'validate' && (
                                  <FormHelperText>
                                    {formatMessage({
                                      id: 'general.error.validate_email',
                                    })}
                                  </FormHelperText>
                                )}
                                {errors.reporterEmail?.type === 'maxLength' && (
                                  <FormHelperText>
                                    {formatMessage(
                                      {
                                        id: 'general.error.max_length_email',
                                      },
                                      { max: EMAIL_MAX_LENGTH },
                                    )}
                                  </FormHelperText>
                                )}
                              </FormControl>

                              <FormControl
                                error={!!errors.reporterPhone}
                                fullWidth
                              >
                                <Controller
                                  name="reporterPhone"
                                  control={control}
                                  defaultValue={''}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      error={!!errors.reporterPhone}
                                      size="small"
                                      label={formatMessage({
                                        id: 'consumer_report_add.label.reporter.phone',
                                      })}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <PhoneIcon
                                              color="primary"
                                              fontSize="small"
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                                {errors.reporterPhone?.type === 'maxLength' && (
                                  <FormHelperText>
                                    {formatMessage(
                                      {
                                        id: 'general.error.max_length_phone',
                                      },
                                      {
                                        max: PHONE_MAX_LENGTH,
                                      },
                                    )}
                                  </FormHelperText>
                                )}
                                {errors.reporterPhone?.type === 'validate' && (
                                  <FormHelperText>
                                    {formatMessage({
                                      id: 'general.error.validate_phone',
                                    })}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </>
                          )}
                        </Stack>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>

                  <Box marginTop={2}>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      fullWidth
                      loading={isSaving}
                      disabled={shouldDisableSaving}
                      color="error"
                      startIcon={<PostAddIcon />}
                    >
                      {formatMessage({ id: 'public_case.button.send_report' })}
                    </LoadingButton>

                    <Button variant="text" onClick={handleGoBack} fullWidth>
                      {formatMessage({ id: 'general.button.cancel' })}
                    </Button>
                  </Box>
                </Stack>
              </Grid>
              <Grid item sm={0} md={2} lg={3}></Grid>
            </Grid>
          </form>
        </Box>
        <PublicFooter />

        <Dialog open={isUnsavedConfirmDialogOpen}>
          <DialogTitle>
            {formatMessage({ id: 'general.unsaved_confirm_dialog.title' })}
          </DialogTitle>
          <DialogContent>
            {formatMessage({ id: 'general.unsaved_confirm_dialog.content' })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDiscard}>
              {formatMessage({
                id: 'general.unsaved_confirm_dialog.button.cancel',
              })}
            </Button>
            <Button onClick={handleContinue} autoFocus variant="contained">
              {formatMessage({
                id: 'general.unsaved_confirm_dialog.button.continue',
              })}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  )
}

export default PublicCaseAddPage
