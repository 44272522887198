import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import Markdown from 'react-markdown'
import styled from '@mui/material/styles/styled'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import { DialogActions, Typography } from '@mui/material'
import usePortalSetting from 'hooks/usePortalSetting'

type NewsPreviousDialogProps = {
  isOpen: boolean
  imageUrl?: string
  titles: Record<string, string>
  bodies: Record<string, string>
  onClose: () => void
  published?: string
}

const PreviewDialog = styled(Dialog)`
  .MuiPaper-root {
    background: ${({ theme }) => theme.palette.info.light};
  }
`

const NewsTitle = styled('h1')`
  font-size: 20px;
  font-weight: 500;
`

const NewsWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  .show-less {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }
`

const ImageWrapper = styled(Stack)<{ url: string }>`
  width: 100%;
  height: 414px;
  align-items: center;

  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background-image: url('${({ url }) => url}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

const NewsPreviousDialog: React.FC<NewsPreviousDialogProps> = ({
  isOpen,
  imageUrl,
  titles,
  bodies,
  onClose,
  published,
}) => {
  const { formatMessage } = useIntl()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)
  const portalSetting = useRecoilValue(portalSettingState)
  const { formatDate } = usePortalSetting()
  const [isSeeMore, setIsSeeMore] = useState(false)

  useEffect(() => {
    if (portalSetting) {
      setSelectedLanguage(portalSetting.defaultLanguage)
    }
  }, [portalSetting])

  const handleClose = (reason: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }

  const publishedDate = useMemo((): string => {
    if (published) {
      return formatDate(published)
    }

    const now = new Date()
    return formatDate(now.toISOString())
  }, [published])

  const handleSeeMore = (): void => {
    setIsSeeMore(true)
  }

  const handleSeeLess = (): void => {
    setIsSeeMore(false)
  }

  return (
    <PreviewDialog
      open={isOpen}
      onClose={(event, reason) => {
        handleClose(reason)
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {formatMessage({
          id: 'news_preview_dialog.header',
        })}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        aria-label={formatMessage({
          id: 'general.button.close',
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogActions>
        <Stack spacing={2}>
          <Typography variant="body2">
            {formatMessage({
              id: 'news_preview_dialog.description',
            })}
          </Typography>

          <Stack direction="row" spacing={1}>
            {supportLanguages.map((language) => (
              <Button
                key={language}
                variant={
                  selectedLanguage === language ? 'contained' : 'outlined'
                }
                onClick={() => {
                  setSelectedLanguage(language)
                }}
                size="small"
              >
                {language}
              </Button>
            ))}
          </Stack>
        </Stack>
      </DialogActions>
      <DialogContent>
        <Stack spacing={2}>
          {selectedLanguage && (
            <NewsWrapper>
              {imageUrl && <ImageWrapper url={imageUrl}></ImageWrapper>}

              <Stack padding={2}>
                <Typography variant="body2">{publishedDate}</Typography>

                {titles && <NewsTitle>{titles?.[selectedLanguage]}</NewsTitle>}

                <div className={isSeeMore ? '' : 'show-less'}>
                  <Markdown>{bodies?.[selectedLanguage]}</Markdown>
                </div>

                <Box textAlign="right">
                  {!isSeeMore && (
                    <Button onClick={handleSeeMore} size="small" variant="text">
                      {formatMessage({
                        id: 'news_preview_dialog.button.see_more',
                      })}
                    </Button>
                  )}

                  {isSeeMore && (
                    <Button onClick={handleSeeLess} size="small" variant="text">
                      {formatMessage({
                        id: 'news_preview_dialog.button.see_less',
                      })}
                    </Button>
                  )}
                </Box>
              </Stack>
            </NewsWrapper>
          )}
        </Stack>
      </DialogContent>
    </PreviewDialog>
  )
}

export default NewsPreviousDialog
