import {
  Box,
  Typography,
  styled,
  IconButton,
  Avatar,
  Accordion as MuiAccordion,
  Stack,
  Chip,
  Link as MuiLink,
  Switch as MuiSwitch,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { DataGrid } from '@mui/x-data-grid'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { MDXEditor } from '@mdxeditor/editor'
import { ThemeMode } from 'commonConstants'

export const ContentWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
  height: 100%;
`

export const SubContentWrapperComponent = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: ${({ theme }) => theme.palette.info.light};
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(2)};
`

type SubContentWrapperProps = {
  children: React.ReactNode
} & React.ComponentPropsWithoutRef<typeof SubContentWrapperComponent>

export const SubContentWrapper: React.FC<SubContentWrapperProps> = ({
  children,
  className,
  ...rest
}) => (
  <SubContentWrapperComponent
    {...rest}
    className={`sub-content ${className || ''}`}
  >
    {children}
  </SubContentWrapperComponent>
)

export const MainHeader = styled('h1')`
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;
  flex-grow: 1;
  align-items: center;
  display: flex;
  gap: 10px;
`

export const SubHeader = styled('h2')`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin: 0;
  flex-grow: 1;
`

export const LightSubHeader = styled('h2')`
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin: 0;
`

export const SubSubHeader = styled('h3')`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
`

export const InfoText = styled(Stack)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.secondary};
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const SmallText = styled(Typography)`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
  line-height: 16px;
`

export const SmallInfoText = styled(SmallText)`
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const SecondaryButton = styled(LoadingButton)`
  background-color: white;
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    background-color: white;
  }

  &:disabled {
    background-color: white;
  }
`

export const BackButtonGrey = styled(IconButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`

export const BackButtonWhite = styled(IconButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: ${({ theme }) => theme.palette.background.paper};
  }
`

export const LoadingWrapper = styled(Box)`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StepNumberWrapper = styled(Avatar)`
  background: ${({ theme }) => theme.palette.primary.main};
  color: white;
  width: 24px;
  height: 24px;
  font-size: 16px;
  font-weight: 400;
  margin: 5px;
`

export const CheckedStepIcon = styled(CheckCircleIcon)`
  font-size: 27px;
  color: ${({ theme }) => theme.palette.success.main};
`

export const Accordion = styled(MuiAccordion)`
  border: none;
  box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  &:before {
    display: 'none';
    background-color: transparent;
    top: 0;
  }
`

export const FormFieldsWrapper = styled(SubContentWrapper)`
  & .MuiInputBase-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  > .MuiStack-root {
    padding: ${({ theme }) => theme.spacing(2)};
    border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
    background: ${({ theme }) => theme.palette.background.paper};
    width: 100%;
    padding: ${({ theme }) => theme.spacing(2)};
  }
`

export const SettingWrapper = styled(Stack)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  overflow: auto;
`

export const DataTable = styled(DataGrid)`
  border: none;

  & .MuiDataGrid-columnHeaders {
    font-size: 12px;
    color: ${({ theme }) =>
      theme.palette.mode === ThemeMode.LIGHT ? '#4c5da6' : '#92A0D8'};
    font-weight: 400;
    border: none;
    background: ${({ theme }) => theme.palette.background.paper};

    & .MuiDataGrid-columnHeader {
      background: ${({ theme }) => theme.palette.background.paper};
    }

    & .MuiDataGrid-columnHeaderTitleContainer {
      padding-left: 8px;
    }
  }

  & .MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
    gap: ${({ theme }) => theme.spacing(1)};
  }

  & .MuiDataGrid-row {
    border: 1px solid;
    border-color: ${({ theme }) =>
      theme.palette.mode === ThemeMode.LIGHT ? '#e9ecfc' : '#4C4E69'};
    border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
    padding: ${({ theme }) => theme.spacing(0.5)};
    cursor: pointer;
    width: 100%;
  }

  & .MuiDataGrid-cell {
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .MuiDataGrid-virtualScroller {
    overflow-x: inherit;
  }

  & .inactive {
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`

export const BasicTag = styled(Chip)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.caption};
  font-weight: 500;
`

export const CategoryTag = styled(BasicTag)`
  background: ${({ theme }) =>
    theme.palette.mode === ThemeMode.LIGHT ? '#E0FFFB' : '#3D7178'};
`

export const Link = styled(MuiLink)`
  text-decoration: none;
  cursor: pointer;
`

export const Switch = styled(MuiSwitch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
    },
    '&.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
    },
    '& + .MuiSwitch-track': {
      opacity: 0.5,
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  },
}))

export const SwitchWrapper = styled(Stack)`
  border: 1px solid #92a0d8;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)};
  width: 100%;
  align-items: center;
  background: ${({ theme }) => theme.palette.info.light};

  .icon-wrapper {
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

export const NotificationWrapper = styled(Box)`
  top: 0px;
  left: 0px;
  background: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.error.contrastText};
  padding: 4px 12px;
  z-index: 1;
  border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
`

export const RadioGroup = styled(MuiRadioGroup)`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const RadioWrapper = styled(FormControlLabel)<{ selected?: string }>`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  border: ${({ selected }) => (selected ? '1.5px solid' : 'none')};
  border-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: ${({ selected }) =>
    selected ? '0px 0px 0px 3px #bbc6f1' : 'none'};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  margin: 0px;

  & .MuiFormControlLabel-label {
    flex-grow: 1;
  }
`

export const SelectBox = styled(Stack)<{
  selected: string
  disabled?: string
}>`
  background-color: ${({ selected, theme }) =>
    selected ? theme.palette.background.paper : theme.palette.info.light};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  border: ${({ selected }) => (selected ? '1.5px solid' : 'none')};
  border-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: ${({ selected }) =>
    selected ? '0px 0px 0px 3px #bbc6f1' : 'none'};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  margin: 0px;
  display: flex;
  width: 50%;
  z-index: ${({ selected }) => (selected ? 1 : 0)};
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.text.disabled : 'inherit'};
`

export const MDEditor = styled(MDXEditor)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  height: 400px;
  overflow: auto;
`
