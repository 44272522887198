import { useRef } from 'react'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { TreeItem as MuiTreeItem } from '@mui/x-tree-view/TreeItem'

import SubCategoryListTree from './SubCategorySelectorTree'
import { CATEGORY_MAX_DEPTH } from 'commonConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import {
  CollapseIcon,
  ExpandIcon,
} from 'components/category/CategoryStyledComponents'
import {
  type CategoryBasic,
  type Category,
} from 'components/category/categoryTypes'

type CategorySelectorItemProps = {
  paths: string[]
  category: Category | CategoryBasic
  selectedItemId: string | null
  onCategoryChange: (ids: string) => void
}

const CategoryControlLabel = styled(FormControlLabel)`
  width: 100%;

  & .MuiRadio-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  & .MuiFormControlLabel-label {
    flex-grow: 1;
  }
`

const TreeItem = styled(MuiTreeItem)<{ selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.info.light : 'transparent'};
`

const CategorySelectorItem: React.FC<CategorySelectorItemProps> = ({
  paths,
  category,
  selectedItemId,
  onCategoryChange,
}) => {
  const ref = useRef<HTMLLIElement>(null)
  const { getLocalizedContent } = usePortalSetting()
  const pathsString = paths.join('|')
  const clonePaths = [...paths]
  clonePaths.pop()

  return (
    <TreeItem
      itemId={category.id}
      ref={ref}
      onFocusCapture={(e) => {
        e.stopPropagation()
      }}
      selected={pathsString === selectedItemId}
      label={
        <Stack direction="row" width="100%">
          <Box flexGrow={1}>
            {category.subcategories && category.subcategories.length > 0 ? (
              <Typography variant="body1">
                {getLocalizedContent(category.names)}
              </Typography>
            ) : (
              <CategoryControlLabel
                labelPlacement="start"
                control={
                  <Radio
                    name={paths.join()}
                    checked={pathsString === selectedItemId}
                  />
                }
                onChange={(): void => {
                  onCategoryChange(pathsString)
                }}
                label={getLocalizedContent(category.names)}
              />
            )}
          </Box>
        </Stack>
      }
      slots={{
        collapseIcon:
          category.subcategories && category.subcategories.length > 0
            ? ExpandIcon
            : undefined,
        expandIcon:
          category.subcategories && category.subcategories.length > 0
            ? CollapseIcon
            : undefined,
      }}
    >
      {paths.length < CATEGORY_MAX_DEPTH && (
        <SubCategoryListTree
          paths={paths}
          categories={category.subcategories ?? []}
          selectedItemId={selectedItemId}
          onCategoryChange={onCategoryChange}
        />
      )}
    </TreeItem>
  )
}

export default CategorySelectorItem
